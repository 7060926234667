import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import TokenTable from '../../../components/TokenTable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 className="docs-b-h1 sprk-b-PageTitle sprk-b-TypeDisplayOne sprk-u-mbl sprk-u-Measure">
  Design Tokens
    </h1>
    <p>{`Our Design Tokens are a catalog from which we base all our visual and branding choices.
It's a central database of our core style attributes.`}</p>
    <p>{`These tokens describe branding decisions in color, typography, spacing, sizing, and much more.`}</p>
    <p>{`Design Tokens are a future-friendly way to create visual consistency between platforms.`}</p>
    <h2>{`Benefits`}</h2>
    <p>{`Design Tokens allow more projects to incorporate our design decisions.`}</p>
    <h3 className="docs-b-h5 sprk-b-TypeDisplayFive sprk-u-mbm sprk-u-Measure">
  Designer Perks
    </h3>
    <ul>
      <li parentName="ul">{`Encourages visual consistency and design consensus across products.`}</li>
      <li parentName="ul">{`Creates easier syncing with brand guidelines.`}</li>
      <li parentName="ul">{`Encourages better support for multi-platform projects.`}</li>
      <li parentName="ul">{`Promotes clear communication with developers and other designers.`}</li>
    </ul>
    <h3 className="docs-b-h5 sprk-b-TypeDisplayFive sprk-u-mbm sprk-u-Measure">
  Developer Perks
    </h3>
    <ul>
      <li parentName="ul">{`Maintains sync with current style updates while minimizing impact to code.`}</li>
      <li parentName="ul">{`Enables Android and iOS projects to access style variables from Spark.`}</li>
      <li parentName="ul">{`Supports future-friendly development. Design Tokens can generate style files for any possible platform in the future.`}</li>
    </ul>
    <h2>{`How to Use Our Design Tokens`}</h2>
    <h3 className="docs-b-h5 sprk-b-TypeDisplayFive sprk-u-mbm sprk-u-Measure">
 For Designers
    </h3>
    <p>{`Use token names to communicate the specific style values of your design.`}</p>
    <p>{`Token names depict style choices that portray color, spacing, typography, and more.`}</p>
    <p>{`For example, the token `}<inlineCode parentName="p">{`sprk-purple-deep`}</inlineCode>{` represents a specific shade of purple. Style consensus makes it clear to everyone which purple you mean.`}</p>
    <h3 className="docs-b-h5 sprk-b-TypeDisplayFive sprk-u-mtl sprk-u-mbm sprk-u-Measure">
 For Developers
    </h3>
    <p>{`For HTML, React, and Angular projects, follow our `}<a parentName="p" {...{
        "href": "/installing-spark"
      }}>{`install guides`}</a>{`. They show you how to import the correct Sass file.`}</p>
    <h4 className="docs-b-h6 sprk-b-TypeDisplaySix sprk-u-mtl sprk-u-mbm sprk-u-Measure">
  How to Change Spark's Default Style Settings in Web (Sass):
    </h4>
    <ol className=" sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mbl">
  <li className="sprk-u-mbs sprk-u-Measure">
    Create a new Sass file.
  </li>
  <li className="sprk-u-mbs sprk-u-Measure">
    Import it before Spark's main sass file.
  </li>
  <li className="sprk-u-mbs sprk-u-Measure">
    Assign a new value to the variable you want to override.
  </li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`These make global changes to your project. Changing a variable affects all instances of its relevant components. For one-off modifications, use `}<a parentName="p" {...{
          "href": "/using-spark/foundations/css-utilities"
        }}>{`CSS utilities`}</a>{`.`}</p>
    </blockquote>
    <p>{`For iOS and Android projects, please `}<a parentName="p" {...{
        "href": "mailto:sparkdesignsystem@rocketmortgage.com"
      }}>{`email the Spark team`}</a>{` and we'll get you started.`}</p>
    <h3>{`Design Token List`}</h3>
    <section className="sprk-u-ptn">
  <TokenTable mdxType="TokenTable" />
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      